<template>
  <div>
    <!-- <header
      id="header-modo-ead"
      class="fd-app-mode-ead-header"
    >
      <div
        id="divBannerMeusCursos"
        class="fd-app-mode-ead-header-bg"
        :style="{ backgroundImage: 'url(' + $store.state.fastPlataforma.banner_plataforma + ')', backgroundColor: '#000' }"
      />
      <div class="fd-app-mode-ead-header-overlay">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col-sm-12 col-md-12 col-lg-8">
              <h1
                :style="{ color: $store.state.fastPersonalizacao.titulo_banner_color ? $store.state.fastPersonalizacao.titulo_banner_color : '#fff', textShadow: '1px 1px #000' }"
              >
                {{ $store.state.fastPersonalizacao.titulo_banner ? $store.state.fastPersonalizacao.titulo_banner : '' }}
              </h1>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <mural-avisos />
            </div>
          </div>
        </div>
      </div>
    </header> -->
    <!-- fd-wrap -->
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb fast-menu-lives-aluno-fast">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                :href="'/plataforma/' + $route.params.id_plataforma"
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Modo aluno</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a href="#">Mural</a>
            </li>
          </ul>
          <ul class="fd-app-breadcrumb d-none fast-menu-lives-aluno-iuea">
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Menu principal</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Meu mural</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-ead-content mt-0">
        <div class="container">
          <div class="mt-3 mb-4">
            <a
              class="btn-novo btn-red-hollow-novo fast-iuea-ml-20"
              :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
            >
              <b-icon-arrow-return-left /> Voltar
            </a>
          </div>
          <div class="row justify-content-center">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <!-- fd-app-welcome -->
                  <section class="fd-app-welcome-novo text-left fast-plataforma-iuea-hide">
                    <h2 class="aluno_font_color mb-0">
                      Mural de 
                      <span>Avisos</span>
                    </h2>
                    <p class="aluno_font_color my-0">
                      Veja os últimos avisos publicados no mural
                    </p>
                    <div>
                      <img
                        :src="require('@/assets/images/separador.png')"
                      >
                    </div>
                  </section>
                  <!-- /fd-app-welcome -->
                  <!-- Avisos pendentes-->
                  <section
                    v-if="fastMuralPendente.TotalResults"                            
                    class="mt-4"
                  >
                    <div
                      v-for="(mural, index) in fastMuralPendente.Data"
                      :key="mural.row_number"
                      class="card card-mural-pendente mb-4"
                      :class="{ 'fade': fastMuralPendenteRemoveItem === index, 'show': fastMuralPendenteRemoveItem !== index }"
                    >
                      <div class="card-header">
                        <div class="d-flex justify-content-between align-items-center">
                          <h5 class="mb-0 mr-3"> {{ mural.titulo_publicacao }} </h5>
                          <a class="text-light" href="#" @click.prevent="removeAvisoMural(mural)"><small>Marcar como lido <b-icon-eye-fill/></small></a>
                        </div>
                      </div>
                      <div class="card-body">
                        <div
                          v-if="mural.html_publicacao"
                          class="card-text fast-html-mural"
                          v-html="mural.html_publicacao"
                        >                                
                        </div>
                        <div class="text-center" v-else-if="mural.loadingMural">
                          <b-icon
                            icon="gear-fill"
                            animation="spin"
                          /> Carregando
                        </div>
                        <div v-else>
                          <p>{{ mural.titulo_publicacao }}...</p>
                          <div class="col-12 text-right">
                            <a href="#" @click.prevent="getMuraldPendente(mural.id_mural), visualizaAvisoMural(mural)">Ver aviso completo</a>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer text-muted" @scroll.passive="handleScroll">
                        <small>{{ exibeDataPublicacao(mural.data_publicacao) }} - Plataforma {{ mural.nome_plataforma }}</small>
                      </div>
                    </div>
                  </section>
                  <!-- /Avisos pendentes-->
                  <!-- Avisos visualizados-->
                  <section
                    v-if="fastMural.Loading"                            
                    class="text-center"
                    :class="fastMuralPendente.TotalResults ? 'mt-0' : 'mt-4'"
                  >
                  <h4><b-icon
                      icon="gear-fill"
                      animation="spin"
                    /> Carregando</h4>
                  </section>
                  <section
                    v-else-if="fastMural.TotalResults"                            
                    class="mt-4"
                  >
                    <div
                      v-for="mural in fastMural.Data"
                      :key="mural.row_number"
                      class="card card-mural-lido mb-4"
                    >
                      <div class="card-header">
                        <div class="d-flex justify-content-between align-items-center">
                          <h5 class="mb-0 mr-3"> {{ mural.titulo_publicacao }} </h5>
                          <small>Vizualizado <b-icon-check/></small>
                        </div>
                      </div>
                      <div class="card-body">
                        <div
                          v-if="mural.html_publicacao"
                          class="card-text fast-html-mural"
                          v-html="mural.html_publicacao"
                        >                                
                        </div>
                        <div class="text-center" v-else-if="mural.loadingMural">
                          <b-icon
                            icon="gear-fill"
                            animation="spin"
                          /> Carregando
                        </div>
                        <div v-else>
                          <p>{{ mural.titulo_publicacao }}...</p>
                          <div class="col-12 text-right">
                            <a href="#" @click.prevent="getMural(mural.id_mural)">Ver aviso completo</a>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer text-muted" @scroll.passive="handleScroll">
                        <small>{{ exibeDataPublicacao(mural.data_publicacao) }} - Plataforma {{ mural.nome_plataforma }}</small>
                      </div>
                    </div>
                  </section>
                  <section
                    v-else                          
                    class="mt-4 text-center"
                  >
                    <h4>Nenhuma publicação no mural</h4>
                  </section>
                  <!-- /Avisos visualizados-->

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /fd-wrap -->
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import muralAvisos from "../components/MuralAvisos";

export default {
  name: "HomeInternoAlunoMural",
  components: {
    muralAvisos,
  },
  mixins: [methods],
  data: function () {
    return {
      fastTemplate: settings.fastTemplate,
      // Mural
      fastMural: {
        Loading: true,
        PageIndex: 1,
        PageSize: 5,
        RangeStart: 0,
        RangeEnd: 0,
        TotalResults: 0,
        Errors: [],
        Data: []
      },
      fastMuralPendente: {
        Loading: true,
        TotalResults: 0,
        Errors: [],
        Data: []
      },
      fastMuralPendenteRemoveItem: null
    }
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'aluno')
        .then(() => {
          //this.getMural();
          this.fastMural.PageIndex = 1;
          this.getMuralPendentePaginado()
            .then(this.getMuralPaginado());
          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    async getMuralPendentePaginado() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
          `api/fast_mural/lista_mural_paginado_nao_visualizado?PageIndex=1&PageSize=100&IdPlataforma=${this.$route.params.id_plataforma}&IdPessoa=${this.$store.state.fastPermissoes.id_pessoa}`,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();   
        this.fastMuralPendente.Loading = false     
        if (json.TotalResults) {
          this.fastMuralPendente.TotalResults = json.TotalResults          

          json.Data.forEach(m => m.loadingMural = false) 
          this.fastMuralPendente.Data = this.fastMuralPendente.Data.concat(json.Data);

          console.log("this.fastMuralPendente", this.fastMuralPendente); 
        }       
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getMuralPaginado() {  
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_mural/lista_mural_paginado?PageIndex=" + this.fastMural.PageIndex + "&PageSize=" + this.fastMural.PageSize + "&IdPlataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();   
        this.fastMural.Loading = false     
        if (json.TotalResults) {
          this.fastMural.RangeStart = json.RangeStart
          this.fastMural.RangeEnd = json.RangeEnd
          this.fastMural.TotalResults = json.TotalResults          

          json.Data.forEach(m => m.loadingMural = false) 
          this.fastMural.Data = this.fastMural.Data.concat(json.Data); 

          // Ajusta lista visualizados
          this.fastMural.Data = this.fastMural.Data.filter(item => {
            return !this.fastMuralPendente.Data.some(pendente => pendente.id_mural === item.id_mural);
          });

          console.log("this.fastMural", this.fastMural); 
          this.fastMural.PageIndex++  
        }       
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getMural(id_mural) {
      this.fastMural.Data = this.fastMural.Data.map(m => {
        m.id_mural == id_mural ? m.loadingMural = true : m.loadingMural = false
        return m;
      }) 
      console.log("this.fastMural.Data", this.fastMural.Data)
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_mural/lista_ead?id_plataforma=" +
            this.$route.params.id_plataforma +
            "&id_mural=" + id_mural,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        
        let obj = Array.from(json);
        if (obj.length) {
          this.fastMural.Data = this.fastMural.Data.map(m => {
            if (m.id_mural == id_mural) {
              m.html_publicacao = obj[0].html_publicacao
            }
            return m
          })
        }
        this.fastMural.Data = this.fastMural.Data.map(m => {
          m.loadingMural = false
          return m
        }) 
        console.log("this.fastMural.Data", this.fastMural.Data)

      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getMuraldPendente(id_mural) {
      this.fastMuralPendente.Data = this.fastMuralPendente.Data.map(m => {
        m.id_mural == id_mural ? m.loadingMural = true : m.loadingMural = false
        return m;
      }) 
      console.log("this.fastMuralPendente.Data", this.fastMuralPendente.Data)
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_mural/lista_ead?id_plataforma=" +
            this.$route.params.id_plataforma +
            "&id_mural=" + id_mural,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        
        let obj = Array.from(json);
        if (obj.length) {
          this.fastMuralPendente.Data = this.fastMuralPendente.Data.map(m => {
            if (m.id_mural == id_mural) {
              m.html_publicacao = obj[0].html_publicacao
            }
            return m
          })
        }
        this.fastMuralPendente.Data = this.fastMuralPendente.Data.map(m => {
          m.loadingMural = false
          return m
        }) 
        console.log("this.fastMuralPendente.Data", this.fastMuralPendente.Data)

      } catch (e) {
        console.log("Erro", e);
      }
    },
    exibeDataPublicacao(mural) {
      let dataPublicacao =
        "Publicado em " +
        mural.split("T")[0] +
        " às " +
        mural.split("T")[1].split(".")[0];
      return dataPublicacao.replace(/-/g, "/");
    }, 
    actionScroll (event) {
      if (Math.ceil(window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        console.log('Math.ceil(window.innerHeight + window.scrollY)', Math.ceil(window.innerHeight + window.scrollY))
        console.log('document.body.offsetHeight', document.body.offsetHeight)
        console.log("Buscando murais...", this.fastMural.PageIndex, this.fastMural.PageSize)
        if (this.fastMural.TotalResults > this.fastMural.Data.length) this.getMuralPaginado()
        else 
          console.log('Total resultados foram exibidos')
      }
    },
    visualizaAvisoMural(aviso){
      let obj = { Id_mural : aviso.id_mural, Id_plataforma : aviso.id_plataforma, Id_pessoa : this.$store.state.fastPermissoes.id_pessoa }
      this.promisePostFastApi(obj, "api/fast_mural_pessoa_visualizou/insere")
          .then(res => {
            console.log(res)
          })
          .catch(e => {
            console.error(e)
          })
    },
    removeAvisoMural(aviso){
      const indexRemover = this.fastMuralPendente.Data.findIndex(item => item.id_mural === aviso.id_mural);
      this.fastMuralPendenteRemoveItem = indexRemover;

      if (indexRemover !== -1) {
        setTimeout(() => {
              this.fastMuralPendente.Data.splice(indexRemover, 1);
              this.visualizaAvisoMural(aviso)
              this.fastMuralPendenteRemoveItem = null;
              this.$store.state.fastMuralTotalAvisos -= 1
            }, 500);
      }
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.actionScroll);
  },
  created() {
    window.addEventListener('scroll', this.actionScroll);
  },
};
</script>

<style scope>
.fast-html-mural img {
  max-width: 100%;
}
</style>
